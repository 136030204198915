<template>
    <v-card flat>
        <v-card-text class="pl-0 pr-0 pb-0">
            <v-row class="mt-n8">
                <v-col>
                    <tile-search-date
                        init-type="WEEK"
                        @changed="searchDateChanged"
                        @inited="searchDateInited"
                        ref="date_range_select"
                        :from-day="searchParam.search_start"
                        :to-day="searchParam.search_end"
                    ></tile-search-date>
                    <v-btn
                        class="mx-2"
                        fab
                        color="#F4F6F8"
                        elevation="0"
                        @click="doSearch(1)"
                        style="float:left;"
                    >
                        <v-icon color="#A5ACB4">
                            mdi-magnify
                        </v-icon>
                    </v-btn>

                </v-col>
            </v-row>
            <v-card flat class="mt-4">
                <v-simple-table class="border-table">
                    <template v-slot:default>
                        <thead>
                        <tr style="background-color:#F4F6F8; height:78px; color:#333333; font-weight:600;">
                            <th class="text-center text-h6">ARS메뉴</th>
                            <th class="text-center text-h6">총 수신전화</th>
                            <th class="text-center text-h6">콜리 연결</th>
                            <th class="text-center text-h6">콜백요청</th>
                        </tr>
                        </thead>
                        <tbody style="color:#555555;">
                        <template v-for="(statItem, statIdx) in statList">
                            <tr style="height:68px;" :key="statIdx">
                                <td style="max-width: 450px; font-size:20px;">{{ statItem.menu_path }}</td>
                                <td class="text-center" style="width: 15%; font-size:20px;">{{ statItem.call_count | number }}</td>
                                <td class="text-center" style="width: 10%; font-size:20px;">{{ statItem.trans_succ_count | number }}</td>
                                <td class="text-center" style="width: 10%; font-size:20px;">{{ statItem.callback_req_count | number }}</td>
                            </tr>
                        </template>
                        <tr style="height:69px;" v-if="!statList || statList.length == 0">
                            <td class="text-center" style="font-size:20px;" colspan="4">조회된 결과가 없습니다</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card>
        </v-card-text>
    </v-card>
</template>
<script>
import {mapGetters, mapState} from 'vuex'

export default {
    name: 'stats_menu_list',
    components: {
        'TileSearchDate': () => import('@/components/stats/tile_search_date.vue'),
    },
    data: () => ({
        searchParam: {
            search_start: '',
            search_end: '',
        },
        initFlags: {'search_date': false},
    }),
    created() {
        this.$store.commit('layoutFlag', true)
        this.$store.commit('menuId', 'stats_menu')

        let lastMenuId = this.$store.getters['lastMenuId']
        if (lastMenuId == 'stats_menu' && this.curSearchParam) {
            Object.assign(this.search, this.curSearchParam)
        }

        this.doSearch(1)
    },
    computed: {
        ...mapState('stats_menu', {
            curSearchParam: state => state.searchParam,
            totalCount: state => state.totalCount,
            statList: state => state.statList,
        }),
        isLoaded() {
            if (this.statList) {
                return true
            }
            return false
        }
    },
    methods: {
        searchDateInited(payload) {
            if (payload && payload.from_day) {
                this.searchParam.search_start = payload.from_day
            }
            if (payload && payload.to_day) {
                this.searchParam.search_end = payload.to_day
            }
            this.initFlags['search_date'] = true
            this.doSearch()
        },
        searchDateChanged(payload) {
            if (payload && payload.from_day) {
                this.searchParam.search_start = payload.from_day
            }
            if (payload && payload.to_day) {
                this.searchParam.search_end = payload.to_day
            }
        },
        doSearch(no) {
            if (!this.initFlags.search_date) {
                return
            }

            this.$store.dispatch('stats_menu/getStatList', {
                searchParam: this.searchParam,
                scb: (stat_list, total_count, va) => {
                },
                fcb: (err, va) => {
                },
                va: this
            })
        },
        answerText(c) {
            if (c == 'Y') {
                return '처리완료'
            }
            return '접수'
        }
    }
}
</script>